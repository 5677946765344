.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  max-width: 640px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-link {
  color: white;
}
